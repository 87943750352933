<template>
    <Content :title="$t('navigation.incidents_types_new')">
        <template v-slot:filters>
            <FiltersBar :with-filters="false" class="flex-grow-0"></FiltersBar>
        </template>
        <template v-slot:main>
            <IncidentsTypesTable @change-page="changePage($event)"
                         @change-amount="changeAmount($event)"
                         @sort="sortingColumn = $event"
                         :loader="loader"
                         @fetch="fetchTypes"
                         :changed-page="pagination.page">
            </IncidentsTypesTable>

            <ManageIncidentType @fetch="fetchTypes"></ManageIncidentType>
        </template>
    </Content>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapFields } from 'vuex-map-fields';
import IncidentsTypesTable from '@/components/incidentsTypes/IncidentsTypesTable';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import ManageIncidentType from '@/components/incidentsTypes/manageIncidentType/ManageIncidentType';
import Content from '@/components/templates/Content.vue';

export default {
    name: 'IncidentsTypes',
    components: { Content, IncidentsTypesTable, FiltersBar, ManageIncidentType },
    data: () => ({
        debouncedFetchTypes: null,
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false,
        sortingColumn: null
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ])
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchTypes();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchTypes();
                }
            }
        }
    },
    methods: {
        fetchTypes () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                'extra_fields[incident_kinds]': 'assigned'
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[name][prefix]'] = this.search;
            }

            this.$store.dispatch('incidents/getIncidentTypes', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchTypes();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchTypes();
        }
    },
    created () {
        this.debouncedFetchTypes = debounce(this.fetchTypes, 1000);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.INCIDENT_TYPES)) {
                vm.fetchTypes();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
