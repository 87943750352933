<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <ValidationObserver ref="obs">
        <v-row>
            <v-col cols="12" sm="6">
                <TextField :label="$t('labels.name')"
                           v-model="name"
                           rules="required">
                </TextField>
                <Autocomplete :label="$t('labels.status')"
                              :checkDefault="true"
                              :items="filterableStatusesList"
                              name="status"
                              :rules="{ required: true, includesDefault: true }"
                              :multiple="true"
                              clearable
                              return-object
                              :loading="isStatusLoading"
                              :search-input.sync="searchStatus"
                              @click="getIncidentStatuses"
                              @load-more-items="getIncidentStatusesLazyLoading"
                              v-model="incident_statuses">
                </Autocomplete>
                <Autocomplete :label="$t('inspections.summary.headers.template')"
                              name="template_id"
                              rules="required"
                              clearable
                              @click="getTemplates(null)"
                              @load-more-items="getTemplatesLazyLoading(null)"
                              :items="filterableTemplates"
                              :loading="isTemplatesLoading"
                              :search-input.sync="searchTemplates"
                              v-model="templateId"></Autocomplete>
                <ColorPicker :label="$t('labels.color')"
                             v-model="color"
                             @clear="color = $event"
                             rules="required"></ColorPicker>
                <TextField :label="$t('labels.code')"
                           v-model="code"
                           name="code">
                </TextField>
                <TextareaField v-model="description"
                               :label="$t('labels.description')"
                ></TextareaField>

            </v-col>
            <v-col cols="12" sm="6">
                <TextField
                    name="lead_time"
                    :label="$t('labels.lead_time')"
                    :rules="{ min_value: 0, integer: true }"
                    v-model="lead_time"
                    >
                </TextField>
                <Autocomplete :label="$t('labels.notifications_for_company')"
                              name="company_typeId"
                              clearable
                              @click="getCompanyTypes(null)"
                              @load-more-items="getCompanyTypesLazyLoading(null)"
                              :items="filterableCompanyTypes"
                              :loading="isCompanyTypesLoading"
                              :search-input.sync="searchCompanyTypes"
                              v-model="company_typeId"></Autocomplete>
                <Autocomplete v-model="notifications_phone"
                              clearable
                              return-object
                              @click="getEmployeesWithPhone(null)"
                              name="phonesForNotifications"
                              :label="$t('labels.notification_phone')"
                              :items="employeesWithPhone"
                              :loading="isEmployeesWithPhoneLoading"
                              :search-input.sync="searchEmployeesWithPhone"
                              :multiple="true"></Autocomplete>
                <Autocomplete v-model="notifications_mail"
                              clearable
                              return-object
                              @click="getEmployeesWithEmail(null)"
                              name="emailsForNotifications"
                              :label="$t('labels.notification_email')"
                              :items="employeesWithEmail"
                              :loading="isEmployeesWithEmailLoading"
                              :search-input.sync="searchEmployeesWithEmail"
                              @load-more-items="getEmployeesWithEmailLazyLoading()"
                              :multiple="true"></Autocomplete>
                <Autocomplete v-model="notify_group_id"
                              clearable
                              @click="getEmployeesGroups(null)"
                              name="groupForNotifications"
                              :label="$t('labels.notification_group')"
                              :items="filterableEmployeesGroups"
                              :loading="isEmployeesGroupsLoading"
                              :search-input.sync="searchEmployeesGroups"
                              @load-more-items="getEmployeesGroupsLazyLoading()"></Autocomplete>
                <Checkbox v-model="anonymous"
                          className="mt-0"
                          :label="$t('incidents.table.headers.anonymous')"></Checkbox>
            </v-col>
        </v-row>
    </ValidationObserver>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextField from '@/components/widgets/forms/TextField';
import Checkbox from '@/components/widgets/forms/Checkbox';
import TextareaField from '@/components/widgets/forms/TextareaField';
import debouncedCompanyTypes from '@/mixins/debounced/debouncedCompanyTypes';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import ColorPicker from '@/components/widgets/forms/ColorPicker';
import debouncedIncidentStatuses from '@/mixins/debounced/debouncedIncidentStatuses';
import debouncedEmployeesWithEmail from '@/mixins/debounced/debouncedEmployeesWithEmail';
import debouncedEmployeesWithPhone from '@/mixins/debounced/debouncedEmployeesWithPhone';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';

export default {
    name: 'ManageIncidentTypeForm',
    components: { ValidationObserver, TextField, TextareaField, Autocomplete, ColorPicker, Checkbox },
    mixins: [debouncedTemplates, debouncedCompanyTypes, debouncedIncidentStatuses, debouncedEmployeesWithEmail, debouncedEmployeesWithPhone, debouncedEmployeesGroups],
    data: () => ({
    }),
    computed: {
        selectOptions () {
            return [
                {
                    text: '1h',
                    value: 1
                },
                {
                    text: '2h',
                    value: 2
                },
                {
                    text: '6h',
                    value: 6
                },
                {
                    text: '8h',
                    value: 8
                },
                {
                    text: '12h',
                    value: 12
                },
                {
                    text: '24h',
                    value: 24
                },
                {
                    text: '48h',
                    value: 48
                },
                {
                    text: '72h',
                    value: 72
                }
            ];
        },
        ...mapState('manageIncident', [
            'incidentTypeData'
        ]),
        ...mapFields('manageIncident', [
            'incidentTypeData.name',
            'incidentTypeData.color',
            'incidentTypeData.code',
            'incidentTypeData.description',
            'incidentTypeData.notify',
            'incidentTypeData.lead_time',
            'incidentTypeData.notify_group_id',
            'incidentTypeData.notifications_mail',
            'incidentTypeData.previousNotifications_mail',
            'incidentTypeData.notifications_phone',
            'incidentTypeData.previousNotifications_phone',
            'incidentTypeData.anonymous',
            'incidentTypeData.templateId',
            'incidentTypeData.previousTemplateId',
            'incidentTypeData.company_typeId',
            'incidentTypeData.incident_statuses',
            'incidentTypeData.previousIncident_statuses',
            'incidentTypeFormVisibility'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapGetters('companyTypes', [
            'filterableCompanyTypes'
        ]),
        ...mapState('templates', [
            'totalTemplatesAmount'
        ]),
        ...mapGetters('incidents', [
            'filterableStatusesList'
        ]),
        ...mapGetters('employees', [
            'employeesWithEmail',
            'employeesWithPhone'
        ]),
        ...mapGetters('employeesGroups', [
            'filterableEmployeesGroups'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        notifyStatus () {
            return (this.notifications_mail.length > 0 || this.notifications_phone.length > 0);
        }
    },
    watch: {
        notifyStatus: {
            handler (val) {
                if (val && val === true) {
                    this.notify = 1;
                } else {
                    this.notify = 0;
                }
            }
        },
        incidentTypeFormVisibility: {
            handler (val) {
                if (val && !this.isEditable) {
                    if (this.templateId) {
                        this.getTemplates(null, {
                            'filter[id]': this.templateId
                        });
                    }
                    if (this.company_typeId) {
                        this.getCompanyTypes({
                            'filter[id]': this.company_typeId
                        });
                    }
                    if (this.incident_statuses) {
                        this.getIncidentStatuses({
                            'filter[id]': this.incident_statuses.map(el => el.value).join(',')
                        });
                    }
                    if (this.notify_group_id) {
                        this.getEmployeesGroups({
                            'filter[id]': this.notify_group_id
                        });
                    }
                    if (this.notifications_mail) {
                        this.getEmployeesWithEmail({
                            'filter[id]': this.notifications_mail.map(el => el.value).join(',')
                        });
                    }
                    if (this.notifications_phone) {
                        this.getEmployeesWithPhone({
                            'filter[id]': this.notifications_phone.map(el => el.value).join(',')
                        });
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>
