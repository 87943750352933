<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="d-contents">
        <v-layout justify-end
                  class="flex-grow-0 mb-3">
            <v-btn color="secondary"
                   v-if="canAddType"
                   @click="openEditForm()"
                   data-test="addNewIncidentBtn"><v-icon left>{{ICONS.ADD}}</v-icon>{{ $t('buttons.add') }}</v-btn>
        </v-layout>

        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      :items="incidentTypes"
                      disable-pagination
                      hide-default-footer
                      @contextmenu:row="showContextMenu"
                      height="100%"
                      fixed-header
                      class="elevation-1 table-wrapper">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:item.name="{ item }">
                <Type v-if="item.id"
                      :type="getType(item)"></Type>
            </template>
            <template v-slot:item.anonymous="{ item }">
                <HintingIcon color="secondary"
                             v-if="item.anonymous"
                             :icon="ICONS.CHECK_CIRCLE"
                             :tooltip="$t('incidents.table.headers.anonymous')"></HintingIcon>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="actions-wrapper">
                    <HintingIcon
                                 v-if="canEditType"
                                 :icon="ICONS.EDIT"
                                 @click="openEditForm(item.id)"
                                 :data-test="item"
                                 :tooltip="$t('actions.edit')"></HintingIcon>
                    <ManageAssignToLocation type="kinds" v-if="canEditType"
                                            :ref="'assign-' + item.id"
                                            :id="item.id"></ManageAssignToLocation>
                    <ButtonWithConfirmation :item-id="item.id"
                                            v-if="canDeleteType"
                                            :callback="deleteType">

                    </ButtonWithConfirmation>
                </div>
            </template>
        </v-data-table>

        <v-menu
            v-model="contextMenuVisible"
            :position-x="contextMenuX"
            :position-y="contextMenuY"
            :close-on-content-click="false"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item v-if="canEditType" @click="openEditForm(contextMenuItem)">
                    <v-list-item-title class="d-flex align-center">
                        <v-icon left dense>{{ ICONS.EDIT }}</v-icon>{{ $t('actions.edit') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canEditType" @click="openAssignForm(contextMenuItem)">
                    <v-list-item-title class="d-flex align-center">
                        <v-icon left dense>{{ ICONS.POINT_ADD }}</v-icon>{{ $t('actions.assign_to_locations') }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider v-if="canDeleteType" class="my-2" />
                <v-list-item v-if="canDeleteType && !confirmDelete" @click.prevent="confirmDelete = true">
                    <v-list-item-title class="d-flex align-center">
                        <v-icon left dense>{{ ICONS.DELETE }}</v-icon>{{ $t('actions.delete') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canDeleteType && confirmDelete" @click="deleteType(contextMenuItem.id)">
                    <v-list-item-title class="d-flex align-center error--text">
                        <v-icon left dense>{{ ICONS.DELETE_CONFIRM }}</v-icon>{{ $t('messages.delete_confirmation') }}
                    </v-list-item-title>
                </v-list-item>
              </v-list>
       </v-menu>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import Pagination from '@/components/widgets/tables/Pagination';
import Type from '@/components/widgets/Type';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import ManageAssignToLocation from '@/components/templatesConfigurations/assignToLocations/ManageAssignToLocation';

export default {
    name: 'IncidentsTypesTable',
    components: { ManageAssignToLocation, SortableHeader, HintingIcon, Pagination, Type, ButtonWithConfirmation },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: () => ({
        ICONS,
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            name: 'asc'
        },
        contextMenuVisible: false,
        contextMenuItem: null,
        contextMenuX: 0,
        contextMenuY: 0,
        confirmDelete: false
    }),
    computed: {
        ...mapState('incidents', [
            'incidentTypes',
            'totalIncidentTypesAmount'
        ]),
        ...mapState('manageIncident', [
            'activeIncidentType'
        ]),
        ...mapFields('manageIncident', [
            'incidentTypeFormVisibility',
            'editedIncidentTypeId'
        ]),
        headers () {
            return [
                {
                    text: this.$t('incidents.table.headers.seq'),
                    value: 'seq',
                    width: '80px',
                    sortable: false
                },
                {
                    text: this.$t('labels.incident_type'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('incidents.table.headers.anonymous'),
                    value: 'anonymous',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '200px',
                    sortable: false
                }
            ];
        },
        canEditType () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INCIDENT_TYPES);
        },
        canDeleteType () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.INCIDENT_TYPES);
        },
        canAddType () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.INCIDENT_TYPES);
        }
    },
    watch: {
        totalIncidentTypesAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        contextMenuVisible: {
            handler (val) {
                if (!val) {
                    this.contextMenuItem = null;
                    this.confirmDelete = false;
                }
            }
        }
    },
    methods: {
        getKey (item) {
            return Object.values(item).flat().toString();
        },
        getType (item) {
            return {
                id: item.id,
                name: item.name,
                color: item.color
            };
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        deleteType (id) {
            return this.$store.dispatch('manageIncident/deleteIncidentType', id)
                .then(() => {
                    this.emitFetch();
                });
        },
        openEditForm (id) {
            if (id) {
                this.$store.dispatch('manageIncident/getActiveIncidentType', id)
                    .then(() => {
                        this.$store.commit('manageIncident/SET_INCIDENT_TYPE_DATA', this.activeIncidentType);
                        this.incidentTypeFormVisibility = true;
                        this.editedIncidentTypeId = id;
                    });
            } else {
                this.incidentTypeFormVisibility = true;
            }
            this.contextMenuVisible = false;
        },
        openAssignForm (id) {
            this.$refs[`assign-${id}`].updateAssign();
            this.contextMenuVisible = false;
        },
        showContextMenu (event, { item }) {
            event.preventDefault();
            this.contextMenuVisible = false;
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.$nextTick(() => {
                this.contextMenuVisible = true;
                this.contextMenuItem = item.id;
            });
        }
    },
    mounted () {
        this.$set(this.sortable, `${this.name}`, null);
    }
};
</script>

<style scoped>
.table-wrapper {
    overflow: hidden;
}

.actions-wrapper {
    display: flex;
    gap: 8px;
}
</style>
